import {
    FETCH_ALL_SAVING,
    FETCH_ONE_SAVING
} from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ALL_SAVING:
            return { ...state, savings: action.payload };
        case FETCH_ONE_SAVING:
            return { ...state, saving: action.payload };
        default:
            return state;
    }
};
