import {
    FETCH_ONE_SCHEDULE,
    FETCH_SCHEDULE,
    FETCH_SCHEDULE_LIST
} from '../actions/types';

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SCHEDULE:
            return { ...state, schedulePage: action.payload };
        case FETCH_ONE_SCHEDULE:
            return { ...state, scheduleSinglePage: action.payload };
        case FETCH_SCHEDULE_LIST: 
            return { ...state, scheduleList: action.payload };
        default:
            return state;
    }
};
