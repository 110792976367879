import { FETCH_FACILITIES, FETCH_ONE_FACILITIES, FETCH_LIST_FACILITIES} from "../actions/types";

export const reducer = (state = {}, action) => {
    
    switch (action.type) {
        case FETCH_FACILITIES:
            return { ...state, pageFacilities: action.payload };
        case FETCH_ONE_FACILITIES:
            return { ...state, pageOneFacilities: action.payload };
        case FETCH_LIST_FACILITIES:
            return { ...state, pageListFacilities: action.payload };
        default:
            return state;
    }
};
