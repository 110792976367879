import {
    FETCH_SLIDER
} from '../actions/types';

export const reducer = (state = {}, action) => {
    
    switch (action.type) {
        case FETCH_SLIDER:
            return { ...state, sliders: action.payload }
        default:
            return state;
    }
};
