import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import App from "../app";
import RequireAuth from "../pages/Auth/require_auth";
import Signout from "../pages/Auth/signout";
import Error404 from "../pages/Error/error404";
import NoInternet from "../pages/Error/noInternet";
import PageLoader from "../components/Loader/page";

const Signin = lazy(() => import("../pages/Auth/signin"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Booking = lazy(() => import("../pages/Transaction"));
const Product = lazy(() => import("../pages/Product"));
const CreateProduct = lazy(() => import("../pages/Product/create-product"));
const UpdateProduct = lazy(() => import("../pages/Product/update-product"));
const Category = lazy(() => import("../pages/Category"));
const Region = lazy(() => import("../pages/Region"));
const RegionCity = lazy(() => import("../pages/City"));
const Tag = lazy(() => import("../pages/Tag"));
const Setting = lazy(() => import("../pages/Setting"));
const Access = lazy(() => import("../pages/Access"));
const User = lazy(() => import("../pages/User"));
const Schedule = lazy(() => import("../pages/Product/schedule"));
const CreateSchedule = lazy(() => import("../pages/Product/create-schedule"));
const UpdateSchedule = lazy(() => import("../pages/Product/update-schedule"));
const AdditionalCondition = lazy(() => import("../pages/AdditionalCondition"));
const Facilities = lazy(() => import("../pages/Facilities"));
const Blog = lazy(() => import("../pages/Blog"));
const BlogCreate = lazy(() => import("../pages/Blog/create-blog"));
const BlogUpdate = lazy(() => import("../pages/Blog/update-blog"));
const Slider = lazy(() => import("../pages/Slider"));
const Logs = lazy(() => import("../pages/Log"));
const Voucher = lazy(() => import("../pages/Voucher"));
const Saving = lazy(() => import("../pages/Saving"));
const Review = lazy(() => import("../pages/Review"));
const ReviewView = lazy(() => import("../pages/Review/view"));

// additional product
const AdditionalProduct = lazy(() => import("../pages/Additional"));
const CreateAdditionalProduct = lazy(() => import("../pages/Additional/create-product"));
const UpdateAdditionalProduct = lazy(() => import("../pages/Additional/update-product"));

const Routes = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Route
                    path='/signin'
                    exact
                    component={(props) => <Signin {...props} />}
                />
                <Route
                    path='/signout'
                    exact
                    component={(props) => <Signout {...props} />}
                />
                <Route
                    path='/404'
                    exact
                    component={(props) => <Error404 {...props} />}
                />
                <Route
                    path='/connection_refused'
                    exact
                    component={(props) => <NoInternet {...props} />}
                />
                <App>
                    <Route
                        path='/dashboard'
                        exact
                        component={RequireAuth(Dashboard)}
                    />
                    <Route
                        path='/reviews'
                        exact
                        component={RequireAuth(Review)}
                    />
                     <Route
                        path='/reviews/:id'
                        exact
                        component={RequireAuth(ReviewView)}
                    />
                    <Route
                        path='/additional-condition'
                        exact
                        component={RequireAuth(AdditionalCondition)}
                    />
                    <Route
                        path='/additional-product'
                        exact
                        component={RequireAuth(AdditionalProduct)}
                    />
                    <Route
                        path='/additional-product/create'
                        exact
                        component={RequireAuth(CreateAdditionalProduct)}
                    />
                    <Route
                        path='/additional-product/:id/update'
                        exact
                        component={RequireAuth(UpdateAdditionalProduct)}
                    />
                    <Route
                        path='/access'
                        exact
                        component={RequireAuth(Access)}
                    />
                    <Route
                        path='/saving'
                        exact
                        component={RequireAuth(Saving)}
                    />
                    <Route
                        path='/facilities'
                        exact
                        component={RequireAuth(Facilities)}
                    />
                    <Route path='/blog' exact component={RequireAuth(Blog)} />
                    <Route
                        path='/blog/create'
                        exact
                        component={RequireAuth(BlogCreate)}
                    />
                    <Route
                        path='/blog/update/:id'
                        exact
                        component={RequireAuth(BlogUpdate)}
                    />
                    <Route
                        path='/setting'
                        exact
                        component={RequireAuth(Setting)}
                    />
                    <Route
                        path='/slider'
                        exact
                        component={RequireAuth(Slider)}
                    />
                    <Route
                        path='/voucher'
                        exact
                        component={RequireAuth(Voucher)}
                    />
                    <Route
                        path='/logs'
                        exact
                        component={RequireAuth(Logs)}
                    />

                    <Route
                        path='/product/:product_id/schedule/create'
                        exact
                        component={RequireAuth(CreateSchedule)}
                    />
                    <Route
                        path='/product/:product_id/schedule/update/:id'
                        exact
                        component={RequireAuth(UpdateSchedule)}
                    />
                    <Route
                        path='/product/:product_id/schedule'
                        exact
                        component={RequireAuth(Schedule)}
                    />
                    <Route
                        path='/product/:id/update'
                        exact
                        component={RequireAuth(UpdateProduct)}
                    />
                    <Route
                        path='/product/create'
                        exact
                        component={RequireAuth(CreateProduct)}
                    />
                    <Route
                        path='/product'
                        exact
                        component={RequireAuth(Product)}
                    />
                    <Route path='/user' exact component={RequireAuth(User)} />
                    <Route
                        path='/booking'
                        exact
                        component={RequireAuth(Booking)}
                    />
                    <Route
                        path='/category'
                        exact
                        component={RequireAuth(Category)}
                    />
                    <Route path='/tag' exact component={RequireAuth(Tag)} />
                    <Route
                        path='/region'
                        exact
                        component={RequireAuth(Region)}
                    />
                    <Route
                        path='/region/:id'
                        exact
                        component={RequireAuth(RegionCity)}
                    />
                    <Route path='/' exact component={RequireAuth(Dashboard)} />
                </App>
            </Switch>
        </Suspense>
    );
};

export default Routes;
