import {
    FETCH_ADDITIONAL_CONDITION,
    FETCH_ADDITIONAL_CONDITION_CREATE,
    FETCH_ADDITIONAL_CONDITION_UPDATE,
    FETCH_LIST_ADDITIONAL_CONDITION
} from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ADDITIONAL_CONDITION:
            return { ...state, pageAdditionalCondition: action.payload };
        case FETCH_LIST_ADDITIONAL_CONDITION:
            return { ...state, pageListAdditionalCondition: action.payload };
        case FETCH_ADDITIONAL_CONDITION_CREATE:
            return { ...state, pageAdditionalCreate: action.payload };
        case FETCH_ADDITIONAL_CONDITION_UPDATE:
            return { ...state, pageAdditionalUpdate: action.payload };
        default:
            return state;
    }
};
