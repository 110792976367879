import { FETCH_BLOGS, FETCH_ONE_BLOG } from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_BLOGS:
            return { ...state, blogs: action.payload };
        case FETCH_ONE_BLOG:
            return { ...state, blog: action.payload };
        default:
            return state;
    }
};
