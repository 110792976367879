import {
    FETCH_DASHBOARD,
    FETCH_DASHBOARD_INFO,
    FETCH_DASHBOARD_PENDING
} from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD:
            return { ...state, homeDashboard: action.payload };
        case FETCH_DASHBOARD_INFO:
            return { ...state, dashboardInfo: action.payload };
        case FETCH_DASHBOARD_PENDING:
            return { ...state, dashboardPending: action.payload };
        default:
            return state;
    }
};
