import { FETCH_ONE_VOUCHER, FETCH_VOUCHER } from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_VOUCHER:
            return { ...state, vouchers: action.payload };
        case FETCH_ONE_VOUCHER:
            return { ...state, voucher: action.payload };
        default:
            return state;
    }
};
