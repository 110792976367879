import { FETCH_TRANSACTION, FETCH_ONE_TRANSACTION } from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TRANSACTION:
            return { ...state, homePageBooking: action.payload };
        case FETCH_ONE_TRANSACTION:
            return { ...state, homePageOneBooking: action.payload };
        default:
            return state;
    }
};
