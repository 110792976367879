import {
    FETCH_USER,
    FETCH_ONE_USER,
    FETCH_USER_COUNTER,
    FETCH_USER_VERIFY_COUNTER
} from '../actions/types';

export const reducer = (state = {}, action) => {

    switch (action.type) {
        case FETCH_USER:
            return { ...state, homePageUser: action.payload}
        case FETCH_ONE_USER:
            return { ...state, homePageOneUser: action.payload }
        case FETCH_USER_COUNTER:
            return { ...state, userCounter: action.payload }
        case FETCH_USER_VERIFY_COUNTER:
            return { ...state, userCounterVerify: action.payload }
        default:
            return state;
    }
};
