import axios from "axios";
import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_CATEGORY,
  FETCH_SETTING,
  FETCH_ACCESS,
  FETCH_DASHBOARD,
  FETCH_USER,
  FETCH_ONE_USER,
  FETCH_NOTIFICATION,
  FETCH_TAG,
  FETCH_PRODUCT,
  FETCH_ONE_PRODUCT,
  FETCH_ADDITIONAL_CONDITION,
  FETCH_ONE_SCHEDULE,
  FETCH_SCHEDULE,
  FETCH_TAG_LIST,
  FETCH_LIST_FACILITIES,
  FETCH_FACILITIES,
  FETCH_ONE_TRANSACTION,
  FETCH_TRANSACTION,
  FETCH_BLOGS,
  FETCH_ONE_BLOG,
  FETCH_SLIDER,
  FETCH_VOUCHER,
  FETCH_CATEGORY_LIST,
  FETCH_LIST_ADDITIONAL_CONDITION,
  FETCH_DASHBOARD_INFO,
  FETCH_DASHBOARD_PENDING,
  FETCH_METRICS,
  FETCH_ADDITIONAL_PRODUCT,
  FETCH_ALL_SAVING,
  FETCH_ONE_SAVING,
  FETCH_SCHEDULE_LIST,
  FETCH_REVIEW,
  FETCH_SINGLE_REVIEW_SESSION,
} from "./types";

import { API_URL, API_KEY, DOCUMENT_API_URL } from "./constant";

let axiosConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
  },
};

axios.interceptors.request.use((req) => {
  return req;
});

const errorTemplate = (text) => {
  return `<div style='position: fixed;bottom: 0;background: #a00000;padding: 10px;z-index: 999999;width: 100%;text-align: center;color: #fff;'>${text}</div>`;
};

const connectionInternetError = () => {
  document.getElementById("error-connection").innerHTML =
    errorTemplate("Connection Error!");
};

const displayError = (error) => {
  if (!error) {
    connectionInternetError();
    return true;
  } else {
    console.log("error >> ", error);
    if (error.data.code === "TOKEN_INVALID") {
      let x = confirm(
        "Keamanan: Sesi Anda telah habis mohon untuk login ulang!"
      );
      if (x) window.location.href = "/signout";
      document.getElementById("error-connection").innerHTML = errorTemplate(
        "Token Expired, Please Login again.!"
      );
    } else if (error.data.code === "INTERNAL_SERVER_ERROR") {
      document.getElementById("error-connection").innerHTML = errorTemplate(
        "Internal Server Error!"
      );
    } else {
      return error.data;
    }
  }
};

export const signinUser = ({ email, password }) => {
  return (dispatch) => {
    axios
      .post(`${API_URL}/v1/admin/auth/sign-in`, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: AUTH_USER });
          localStorage.setItem("adm_token", response.data.data.token);
          localStorage.setItem(
            "_assets",
            JSON.stringify({
              id: response.data.data.id,
              name: response.data.data.name,
              email: response.data.data.email,
              type: response.data.data.type,
            })
          );

          window.location.href = "/dashboard";
        } else {
          dispatch(authError(response.data.message));
        }
      })
      .catch((err) => {
        if (!err.response) {
          dispatch(authError("Server is Down"));
        } else {
          dispatch(authError("Bad login information"));
        }
      });
  };
};

export const authError = (error) => {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
};

export const signoutUser = () => {
  localStorage.removeItem("adm_token");
  localStorage.removeItem("adm_setting");
  localStorage.removeItem("adm_auth");
  setTimeout(function () {
    window.location.href = "/signin";
  }, 2500);
  return { type: UNAUTH_USER };
};

export const fetchLogs = (start, end) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/../metrics", {
        headers: {
          "Content-Type": `text/plain`,
        },
        params: { start, end },
      })
      .then((response) => {
        console.log(response);

        dispatch({
          type: FETCH_METRICS,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * START DASHBOARD
 =======================================================================================*/
export const fetchDashboard = (start, end) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/dashboard/chart", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { start, end },
      })
      .then((response) => {
        dispatch({
          type: FETCH_DASHBOARD,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchADashboardInfo = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/dashboard/info-card", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_DASHBOARD_INFO,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchADashboardPending = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/dashboard/transaction", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_DASHBOARD_PENDING,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchNotification = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/notification", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_NOTIFICATION,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END DASHBOARD
 =======================================================================================*/

/**=======================================================================================
 * START CATEGORY
 =======================================================================================*/
export const fetchAllCategories = (page, keyword, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/category", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword: keyword, page: page, limit: limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_CATEGORY,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchListCategories = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/category/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_CATEGORY_LIST,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createCategory = (
  name,
  description,
  image_file_id,
  keyword,
  type,
  tags = []
) => {
  return (dispatch) => {
    return axios
      .post(
        API_URL + "/v1/admin/category",
        {
          name,
          description,
          image_file_id,
          keyword,
          type,
          tags,
        },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllCategories());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateCategory = (
  id,
  name,
  description = null,
  image_file_id,
  keyword = null,
  type,
  tags = [],
  page
) => {
  const payload = {
    name,
    description,
    type,
    image_file_id,
    keyword,
  };

  if (tags.length > 0) payload.tags = tags;

  return (dispatch) => {
    return axios
      .put(API_URL + "/v1/admin/category/" + id, payload, axiosConfig)
      .then((response) => {
        dispatch(fetchAllCategories(page));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteCategory = (id, page) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/category/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllCategories(page));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END CATEGORY
 =======================================================================================*/

/**=======================================================================================
 * START ADDITIONAL CONDITION
 =======================================================================================*/
export const fetchAllAdditionalCondition = (page, keyword, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/additional-condition", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword, page, limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_ADDITIONAL_CONDITION,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchListAdditionalCondition = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/additional-condition/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_LIST_ADDITIONAL_CONDITION,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createAdditionalCondition = (
  draft_name,
  name,
  image_file_id,
  description,
  use_content = false,
  content = null
) => {
  const payload = { draft_name, name, description, use_content };

  if (use_content) payload.content = content;

  if (image_file_id) payload.image_file_id = image_file_id;

  return (dispatch) => {
    return axios
      .post(API_URL + "/v1/admin/additional-condition", payload, axiosConfig)
      .then((response) => {
        dispatch(fetchAllAdditionalCondition());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateAdditionalCondition = (
  id,
  draft_name,
  name,
  image_file_id,
  description,
  use_content = false,
  content = "",
  page
) => {
  const payload = { draft_name, name, description, use_content };

  if (use_content) payload.content = content;

  if (image_file_id) payload.image_file_id = image_file_id;

  return (dispatch) => {
    return axios
      .put(
        API_URL + "/v1/admin/additional-condition/" + id,
        payload,
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllAdditionalCondition(page));

        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteAdditionalCondition = (id, page) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/additional-condition/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllAdditionalCondition(page));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END ADDITIONAL CONDITION
 =======================================================================================*/

/**=======================================================================================
 * START TAG
 =======================================================================================*/
export const fetchAllTag = (page, keyword, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/tag", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword: keyword, page: page, limit: limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_TAG,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchTagList = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/tag/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_TAG_LIST,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createTag = (name, description, keyword) => {
  return (dispatch) => {
    return axios
      .post(
        API_URL + "/v1/admin/tag",
        {
          name,
          description,
          keyword,
        },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllTag());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateTag = (
  id,
  name,
  description = null,
  keyword = null,
  page
) => {
  return (dispatch) => {
    return axios
      .put(
        API_URL + "/v1/admin/tag/" + id,
        {
          name,
          description,
          keyword,
        },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllTag(page));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteTag = (id, page) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/tag/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllTag(page));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END TAG
 =======================================================================================*/

/**=======================================================================================
 * START USER
 =======================================================================================*/
export const fetchAllUser = (page = 1, keyword = null, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/user", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword, page, limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_USER,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateUser = (id, status, verify, message) => {
  return (dispatch) => {
    const data = {};
    status ? (data.status = status) : "";
    verify ? (data.isIdVerify = verify) : "";
    message ? (data.message = message) : "";

    return axios
      .put(API_URL + "/v1/admin/user/" + id, data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllUser());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchOneUser = (id) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/user/" + id, axiosConfig)
      .then((response) => {
        dispatch({
          type: FETCH_ONE_USER,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END USER
 =======================================================================================*/

/**=======================================================================================
 * START PRODUCT
 =======================================================================================*/
export const fetchAllProduct = (page = 1, keyword = null, limit = 10, type) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/product", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword: keyword, page: page, limit: limit, type },
      })
      .then((response) => {
        dispatch({
          type: FETCH_PRODUCT,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createProduct = (data) => {
  return (dispatch) => {
    return axios
      .post(API_URL + "/v1/admin/product", data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllProduct());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateProduct = (id, data) => {
  return (dispatch) => {
    return axios
      .put(API_URL + "/v1/admin/product/" + id, data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllProduct());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchOneProduct = (id) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/product/" + id, axiosConfig)
      .then((response) => {
        dispatch({
          type: FETCH_ONE_PRODUCT,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteProduct = (id) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/product/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllProduct());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END PRODUCT
 =======================================================================================*/

/**=======================================================================================
 * START TRAVEL SCHEDULE
 =======================================================================================*/
export const fetchAllSchedule = (
  product_id,
  page = 1,
  keyword = null,
  limit = 10
) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/schedule/" + product_id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword: keyword, page: page, limit: limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_SCHEDULE,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createSchedule = (product_id, data) => {
  return (dispatch) => {
    return axios
      .post(API_URL + "/v1/admin/schedule/" + product_id, data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllSchedule(product_id));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateSchedule = (product_id, id, data) => {
  return (dispatch) => {
    return axios
      .put(
        `${API_URL}/v1/admin/schedule/${product_id}/${id}`,
        data,
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllSchedule(product_id));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchOneSchedule = (product_id, id) => {
  return (dispatch) => {
    return axios
      .get(`${API_URL}/v1/admin/schedule/${product_id}/${id}`, axiosConfig)
      .then((response) => {
        dispatch({
          type: FETCH_ONE_SCHEDULE,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteSchedule = (product_id, id) => {
  return (dispatch) => {
    return axios
      .delete(`${API_URL}/v1/admin/schedule/${product_id}/${id}`, axiosConfig)
      .then((response) => {
        dispatch(fetchAllSchedule(product_id));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END TRAVEL SCHEDULE
 =======================================================================================*/

/**=======================================================================================
* START BOOKING
=======================================================================================*/
export const fetchAllBooking = (params) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/transaction", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params,
      })
      .then((response) => {
        dispatch({
          type: FETCH_TRANSACTION,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchOneBooking = (id) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/transaction/" + id, axiosConfig)
      .then((response) => {
        dispatch({
          type: FETCH_ONE_TRANSACTION,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateBooking = (id, status, message) => {
  return (dispatch) => {
    return axios
      .put(
        API_URL + "/v1/admin/transaction/" + id,
        { status: status, message: message },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllBooking());
        return response;
      })

      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END BOOKING
 =======================================================================================*/

/**=======================================================================================
* START SETTING
=======================================================================================*/

export const getSetting = () => {
  return (dispatch) => {
    return axios
      .get(`${API_URL}/v1/admin/setting`, axiosConfig)
      .then((response) => {
        localStorage.setItem("setting", JSON.stringify(response.data.data));

        dispatch({ type: FETCH_SETTING, payload: response.data });

        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateSetting = (payload) => {
  return (dispatch) => {
    return axios
      .patch(`${API_URL}/v1/admin/setting`, payload, axiosConfig)
      .then((response) => {
        dispatch(getSetting());

        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const uploadImage = (image, source) => {
  return (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", image);

    let _source = "";
    if (source) {
      _source = `?source=${source}`;
    }

    return axios
      .post(DOCUMENT_API_URL + "/v1/upload" + _source, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const uploadDocument = (file) => {
  return (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);

    return axios
      .post(DOCUMENT_API_URL + "/v1/upload?source=files", bodyFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const uploadImageAttribute = (image) => {
  return (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", image);

    return axios
      .post(DOCUMENT_API_URL + "/v1/upload?source=attributes", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
* END SETTING
=======================================================================================*/

/**=======================================================================================
* START ACCESS
=======================================================================================*/
export const fetchAllAccess = (keyword = null) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/access", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword },
      })
      .then((response) => {
        dispatch({
          type: FETCH_ACCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createAccess = (name, email, password, passwordConfirmation) => {
  return (dispatch) => {
    const data = {
      name: name,
      email: email,
      password: password,
      passwordConfirmation: passwordConfirmation,
    };

    return axios
      .post(API_URL + "/v1/admin/setting/access", data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllAccess());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateAccess = (id, name) => {
  return (dispatch) => {
    return axios
      .put(
        API_URL + "/v1/admin/setting/access/" + id,
        { name: name },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllAccess());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updatePasswordAccess = (id, password, passwordConfirmation) => {
  return (dispatch) => {
    return axios
      .put(
        API_URL + "/v1/admin/setting/access/password/" + id,
        {
          password: password,
          passwordConfirmation: passwordConfirmation,
        },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllAccess());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteAccess = (id) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/setting/access/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllAccess());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END ACCESS
 =======================================================================================*/

/**=======================================================================================
* START FACILITIES
=======================================================================================*/
export const fetchAllFacilities = (page = 1, keyword = null, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/facilities", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { page, keyword, limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_FACILITIES,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchListFacilities = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/facilities/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_LIST_FACILITIES,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createFacilities = (
  draft_name,
  title,
  icon,
  description,
  group
) => {
  return (dispatch) => {
    const data = {
      draft_name,
      title,
      icon,
      description,
      group,
    };
    return axios
      .post(API_URL + "/v1/admin/facilities", data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllFacilities());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateFacilities = (
  id,
  draft_name,
  title,
  icon,
  description,
  group,
  page
) => {
  return (dispatch) => {
    return axios
      .put(
        API_URL + "/v1/admin/facilities/" + id,
        { draft_name, title, icon, description, group },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllFacilities(page));
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteFacilities = (id) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/facilities/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllFacilities());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END FACILITIES
 =======================================================================================*/

/**=======================================================================================
* START BLOG
=======================================================================================*/
export const fetchAllBlogs = (page = 1, keyword = null, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/blog", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { page, keyword, limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_BLOGS,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchOneBlog = (id) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/blog/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_ONE_BLOG,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createBlog = (data) => {
  return (dispatch) => {
    return axios
      .post(API_URL + "/v1/admin/blog", data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllBlogs());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateBlog = (id, data) => {
  return (dispatch) => {
    return axios
      .put(API_URL + "/v1/admin/blog/" + id, data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllBlogs());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteBlog = (id) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/blog/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllBlogs());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END BLOG
 =======================================================================================*/

/**=======================================================================================
* START SLIDER
=======================================================================================*/
export const fetchAllSlider = (page = 1, keyword = null, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/slider", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { page, keyword, limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_SLIDER,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createSlider = (data) => {
  return (dispatch) => {
    return axios
      .post(API_URL + "/v1/admin/slider", data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllSlider());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateSlider = (id, data, page) => {
  return (dispatch) => {
    return axios
      .put(API_URL + "/v1/admin/slider/" + id, data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllSlider());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteSlider = (id) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/slider/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllSlider());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END SLIDER
 =======================================================================================*/

/**=======================================================================================
* START VOUCHER
=======================================================================================*/
export const fetchAllVoucher = (page = 1, keyword = null, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/voucher", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { page, keyword, limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_VOUCHER,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createVoucher = (data) => {
  return (dispatch) => {
    return axios
      .post(API_URL + "/v1/admin/voucher", data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllVoucher());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateVoucher = (id, data, page) => {
  return (dispatch) => {
    return axios
      .put(API_URL + "/v1/admin/voucher/" + id, data, axiosConfig)
      .then((response) => {
        dispatch(fetchAllVoucher());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const deleteVoucher = (id) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/v1/admin/voucher/" + id, axiosConfig)
      .then((response) => {
        dispatch(fetchAllVoucher());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END VOUCHER
 =======================================================================================*/

/**=======================================================================================
 * START ADDITIONAL PRODUCT
 =======================================================================================*/
export const fetchAllAdditionalProduct = (
  page = 1,
  keyword = null,
  limit = 10
) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/additional-product", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword: keyword, page: page, limit: limit },
      })
      .then((response) => {
        console.log("dapat > ", response.data);
        dispatch({
          type: FETCH_ADDITIONAL_PRODUCT,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const updateAdditionalProduct = (
  id,
  title,
  description = null,
  product_type,
  product_code,
  price,
  status
) => {
  const payload = {
    title,
    description,
    product_type,
    product_code,
    price,
    status,
  };

  return (dispatch) => {
    return axios
      .put(API_URL + "/v1/admin/additional-product/" + id, payload, axiosConfig)
      .then((response) => {
        dispatch(fetchAllAdditionalProduct());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END ADDITIONAL PRODUCT
 =======================================================================================*/

/**=======================================================================================
 * START SAVING 
 =======================================================================================*/

export const fetchAllSaving = (
  page = 1,
  keyword = null,
  limit = 10,
  status = undefined
) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/saving", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword, page, limit, status },
      })
      .then((response) => {
        console.log("dapat > ", response.data);
        dispatch({
          type: FETCH_ALL_SAVING,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const fetchOneSaving = (id) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/saving/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        console.log("dapat > ", response.data);
        dispatch({
          type: FETCH_ONE_SAVING,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END SAVING 
 =======================================================================================*/

// DOWNLOAD PDF

/**
 *
 * @param {*} type  "TRAVEL_PACKAGE" | "CRUISE" | "GO_EVERYDAY"
 * @param {*} id
 * @param {*} productName
 * @returns
 */
export const getPDFDownload = (type, id, productName) => {
  let url = "travel";

  if (type === "CRUISE") url = "cruise";
  else if (type === "GO_EVERYDAY") url = "go-everyday";

  try {
    return axios
      .get(`${API_URL}/v1/public/pdf/${url}/${id}`, {
        headers: {
          "x-api-key": `${API_KEY}`,
          "Content-Type": "application/pdf",
          "Content-Disposition": `inline; filename="DTRAVELIND - ${productName}_.pdf"`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `DTRAVELIND - ${productName}_.pdf`);
        document.body.appendChild(link);
        link.click();

        return true;
      })
      .catch((err) => {
        HandleError(err);
      });
  } catch (err) {
    console.log("FAILED TO DOWNLOAD");
    return false;
  }
};

export const fetchListSchedule = () => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/schedule/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_SCHEDULE_LIST,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * START REVIEW
 =======================================================================================*/
export const fetchAllReview = (page, keyword, limit = 10) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/reviews", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
        params: { keyword: keyword, page: page, limit: limit },
      })
      .then((response) => {
        dispatch({
          type: FETCH_REVIEW,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const getSingleReviewSession = (id) => {
  return (dispatch) => {
    return axios
      .get(API_URL + "/v1/admin/reviews/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_token")}`,
        },
      })
      .then((response) => {
        dispatch({
          type: FETCH_SINGLE_REVIEW_SESSION,
          payload: response.data,
        });
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const createReviewSession = (target_type, targets) => {
  return (dispatch) => {
    return axios
      .post(
        API_URL + "/v1/admin/reviews/blasting",
        {
          target_type,
          targets: targets.split(",").map(i => i.trim()),
        },
        axiosConfig
      )
      .then((response) => {
        dispatch(fetchAllReview());
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

export const retrigerReview = (
  id
) => {
  const payload = {};

  if (tags.length > 0) payload.tags = tags;

  return (dispatch) => {
    return axios
      .patch(API_URL + "/v1/admin/review/" + id + "/retrigger", payload, axiosConfig)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return displayError(err.response);
      });
  };
};

/**=======================================================================================
 * END CATEGORY
 =======================================================================================*/
