import { combineReducers } from "redux";
import { reducer as authReducer } from "./auth";
import { reducer as bookingReducer } from "./booking";
import { reducer as productReducer } from "./product";
import { reducer as categoryReducer } from "./category";
import { reducer as tagReducer } from "./tag";
import { reducer as settingReducer } from "./setting";
import { reducer as accessReducer } from "./access";
import { reducer as dashboardReducer } from "./dashboard";
import { reducer as userReducer } from "./user";
import { reducer as notificationReducer } from "./notification";
import { reducer as additionalConditionReducer } from "./additional-condition";
import { reducer as facilitiesReducer } from "./facilities";
import { reducer as scheduleReducer } from "./schedule";
import { reducer as blogReducer } from "./blog";
import { reducer as sliderReducer } from "./slider";
import { reducer as voucherReducer } from "./voucher";
import { reducer as metricsReducer } from "./metrics";
import { reducer as additionalProductReducer } from "./additional-product";
import { reducer as savingReducer } from "./saving";
import { reducer as reviewReducer } from "./review";

import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    booking: bookingReducer,
    product: productReducer,
    schedule: scheduleReducer,
    category: categoryReducer,
    tag: tagReducer,
    facilities: facilitiesReducer,
    setting: settingReducer,
    access: accessReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    notification: notificationReducer,
    additionalCondition: additionalConditionReducer,
    blog: blogReducer,
    slider: sliderReducer,
    voucher: voucherReducer,
    metrics: metricsReducer,
    additionalProduct: additionalProductReducer,
    saving: savingReducer,
    review: reviewReducer,
});

export default rootReducer;
