import {
    FETCH_REVIEW,
    FETCH_SINGLE_REVIEW_SESSION,
} from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_REVIEW:
            return { ...state, review: action.payload };
        case FETCH_SINGLE_REVIEW_SESSION:
            return { ...state, reviewSession: action.payload };
        default:
            return state;
    }
};
