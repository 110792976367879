import dotenv from "dotenv";

dotenv.config();

// ENV

// BASE_URL
if (!process.env.REACT_APP_BASE_URL)
    throw new Error("REACT_APP_BASE_URL not define");
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// API_URL
if (!process.env.REACT_APP_API_URL)
    throw new Error("REACT_APP_API_URL not define");
export const API_URL = process.env.REACT_APP_API_URL;


// API_KEY
if (!process.env.REACT_APP_API_KEY)
    throw new Error("REACT_APP_API_KEY not define");
export const API_KEY = process.env.REACT_APP_API_KEY;

// BASE_URL
if (!process.env.REACT_APP_DOCUMENT_API_URL)
    throw new Error("REACT_APP_DOCUMENT_API_URL not define");
export const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT_API_URL;
