import {
    FETCH_CATEGORY,
    FETCH_CATEGORY_LIST,
    UPLOAD_IMAGE_CATEGORY
} from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_CATEGORY:
            return { ...state, pageCategory: action.payload };
        case FETCH_CATEGORY_LIST:
            return { ...state, categoryList: action.payload };
        case UPLOAD_IMAGE_CATEGORY:
            return { ...state, imageCategory: action.payload };
        default:
            return state;
    }
};
