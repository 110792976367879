import {
    FETCH_ACCESS
} from '../actions/types';

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ACCESS:
            return { ...state, homePageAccess: action.payload}
        default:
            return state;
    }
};
