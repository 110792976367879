import {
    FETCH_PRODUCT,
    FETCH_ONE_PRODUCT
} from '../actions/types';

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PRODUCT:
            return { ...state, productPage: action.payload };
        case FETCH_ONE_PRODUCT:
            return { ...state, productSinglePage: action.payload };
        default:
            return state;
    }
};
