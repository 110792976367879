import React, { Component } from "react";
import { Navbar, Alert } from "react-bootstrap";
import * as Icon from "react-feather";
import styles from "./Header.module.css";
import LeftMenu from "./leftmenu";
import { Link } from "react-router-dom";
import history from "../../history";
import moment from "moment";
import { connect } from "react-redux";
import * as actions from "../../actions";

class Topbar extends Component {
    constructor() {
        super();
        this.state = {
            notificationShow: "-665px",
            backdropShow: "none",
            showMenu: "",
            backDropMenu: "none"
        };
        // this.renderListNotification = this.renderListNotification.bind(this)
        // this.showNotification = this.showNotification.bind(this)
        // this.hideNotification = this.hideNotification.bind(this)
        this.changeStatusMenu = this.changeStatusMenu.bind(this);
        this.closeStatusMenu = this.closeStatusMenu.bind(this);
        this.showStatusMenu = this.showStatusMenu.bind(this);
        this.handleClickMenu = this.handleClickMenu.bind(this);
    }

    fetchingData() {
        // this.props.fetchNotification(this.state.keyword);
    }

    componentWillMount() {
        // this.fetchingData()
        // if (localStorage.getItem('adm_setting') != null){
        //     this.setState({ setting: JSON.parse(localStorage.getItem('adm_setting'))})
        // }else{
        //     window.location.href="../../signout"
        //     // history.push('/signout')
        // }

        if (window.innerWidth <= 992) {
            this.closeStatusMenu();
            this.setState({ backDropMenu: "block" });
        } else {
            this.showStatusMenu();
        }
    }

    // showNotification(){
    //     this.setState({ notificationShow: '0', backdropShow: 'block' })
    // }
    // hideNotification() {
    //     this.setState({ notificationShow: '-665px', backdropShow: 'none' })
    // }

    changeStatusMenu() {
        if (this.state.showMenu === "active") {
            this.closeStatusMenu();
        } else {
            this.showStatusMenu();
        }
    }

    showStatusMenu() {
        document.body.classList.add("menuActive");
        this.setState({ showMenu: "active" });
    }

    closeStatusMenu() {
        document.body.classList.remove("menuActive");
        this.setState({ showMenu: "" });
    }

    handleClickMenu() {
        if (window.innerWidth <= 992) {
            this.closeStatusMenu();
        }
    }

    // renderListNotification(){

    //     return this.props.notification.data.map((item, index) => {

    //         let targetUrl = '#'
    //         if (item.module === 'BOOKING'){
    //             targetUrl = '/booking?key=' + item.target
    //         } else if (item.module === 'ACTIVITY') {
    //             targetUrl = '/activity?key=' + item.target
    //         }else if (item.module === 'USER') {
    //             targetUrl = '/user?key=' + item.target
    //         } else if (item.module === 'PAYOUT') {
    //             targetUrl = '/payout?key=' + item.target
    //         }

    //         return <Link key={index} to={targetUrl} style={{textDecoration: 'none'}}>
    //             <Alert variant={item.type.toLowerCase()} >
    //                 <Alert.Heading>{item.title}</Alert.Heading>
    //                 <small>{item.description}</small>
    //                 <hr />
    //                 <small><Icon.Clock size={16}/>&nbsp; {moment(item.createdAt).fromNow()}</small>
    //             </Alert>
    //         </Link>
    //     })

    // }

    render() {
        return (
            <React.Fragment>
                {/* {
                    this.state.notificationShow ? (
                        <React.Fragment>
                            <div className={styles._hdr_Ntv} style={{ right: this.state.notificationShow }}>
                                <div className="btn-close-alert" onClick={this.hideNotification}><Icon.X /></div> <p>All Notification</p><hr />
                                <div className="alert-container" onClick={this.hideNotification}>
                                    {!this.props.notification ? '' : this.renderListNotification()}
                                </div>
                            </div>
                            <div className="backdrop" style={{ display: this.state.backdropShow }} onClick={this.hideNotification}></div>
                        </React.Fragment>
                        
                    ) : ''
                } */}

                <LeftMenu
                    status={this.state.showMenu}
                    onClose={this.handleClickMenu}
                />
                {this.state.showMenu === "active" ? (
                    <div
                        className='left-menu-backdrop'
                        style={{ display: this.state.backDropMenu }}
                        onClick={this.closeStatusMenu}></div>
                ) : null}
                <Navbar
                    collapseOnSelect
                    expand='lg'
                    bg='primary'
                    variant='primary'
                    className={styles._Prtq_navbar}>
                    <span
                        className='toggle-menu'
                        onClick={this.changeStatusMenu}>
                        <Icon.Menu color='white' size={18} />
                    </span>
                    <img
                        className={styles._logo}
                        src={this.state.setting?.logoHeader}
                        title={this.state.setting?.webname}
                    />

                    <div className='justify-content-end'>
                        <a
                            className='p-3 topbar-menu '
                            style={{ cursor: "pointer" }}
                            onClick={this.showNotification}
                            title='Notification'>
                            <Icon.Bell color='white' size={18} />
                        </a>
                        <Link
                            className='p-3 topbar-menu'
                            to='/signout'
                            title='Signout'>
                            <Icon.LogOut color='white' size={18} />
                        </Link>
                    </div>
                </Navbar>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // notification: state.notification.homePageNotification
    };
};

export default connect(mapStateToProps, actions)(Topbar);
