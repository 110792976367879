import { FETCH_TAG, FETCH_TAG_LIST } from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TAG:
            return { ...state, tagPage: action.payload };
        case FETCH_TAG_LIST:
            return { ...state, tagListPage: action.payload };
        default:
            return state;
    }
};
