import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import styles from "./Header.module.css";
import { connect } from "react-redux";
import * as actions from "../../actions";

class LeftMenu extends Component {
    constructor() {
        super();
    }

    componentDidMount() {}

    render() {
        return (
            <div className={`${styles.dl_lFCtr} dl-sb ${this.props.status}`}>
                <div className={styles._i}>
                    <Link to='/dashboard' onClick={this.props.onClose}>
                        <Icon.Home size={18} />
                        <span>Dashboard</span>
                    </Link>
                    <Link to='/booking' onClick={this.props.onClose}>
                        <Icon.Calendar size={18} />
                        <span>Booking</span>
                    </Link>
                    <Link to='/user' onClick={this.props.onClose}>
                        <Icon.Users size={18} />
                        <span>User</span>
                        <div
                            style={{
                                position: "absolute",
                                right: "34px"
                            }}></div>
                    </Link>
                    <Link to='/saving' onClick={this.props.onClose}>
                        <Icon.DollarSign size={18} />
                        <span>Saving</span>
                        <div
                            style={{
                                position: "absolute",
                                right: "34px"
                            }}><span style={{ color: "white",background: "#d50000",fontSize: "10px",padding: "3px 7px",borderRadius: "15px"}}>new</span></div>
                    </Link>
                </div>
                <div className={`${styles._i} ${styles._dk}`}>
                    <Link to='/product' onClick={this.props.onClose}>
                        <Icon.Briefcase size={18} />
                        <span>Product</span>
                    </Link>
                    <Link to='/category' onClick={this.props.onClose}>
                        <Icon.Grid size={18} />
                        <span>Category</span>
                    </Link>
                    <Link to='/tag' onClick={this.props.onClose}>
                        <Icon.Tag size={18} />
                        <span>Tag</span>
                    </Link>
                    <Link
                        to='/additional-condition'
                        onClick={this.props.onClose}>
                        <Icon.Twitch size={18} />
                        <span>Additional Condition</span>
                    </Link>
                    <Link to='/facilities' onClick={this.props.onClose}>
                        <Icon.Trello size={18} />
                        <span>Facilities</span>
                    </Link>
                    <Link to='/voucher' onClick={this.props.onClose}>
                        <Icon.Server size={18} />
                        <span>Voucher</span>
                    </Link>
                    <Link
                        to='/additional-product'
                        onClick={this.props.onClose}>
                        <Icon.Archive size={18} />
                        <span>Additional Product</span>
                    </Link>
                </div>
                <div className={`${styles._i} ${styles._dk}`}>
                    <Link
                        to='/reviews'
                        onClick={this.props.onClose}>
                        <Icon.MessageCircle size={18} />
                        <span>Reviews</span>
                    </Link>
                </div>
                <div className={`${styles._i} ${styles._dk}`}>
                    <Link to='/blog' onClick={this.props.onClose}>
                        <Icon.FileText size={18} />
                        <span>Blog</span>
                    </Link>
                    <Link to='/setting' onClick={this.props.onClose}>
                        <Icon.Settings size={18} />
                        <span>Setting</span>
                    </Link>
                    <Link to='/slider' onClick={this.props.onClose}>
                        <Icon.Image size={18} />
                        <span>Slider</span>
                    </Link>
                    <Link to='/access' onClick={this.props.onClose}>
                        <Icon.Key size={18} />
                        <span>Access</span>
                    </Link>
                    <Link to='/logs' onClick={this.props.onClose}>
                        <Icon.Key size={18} />
                        <span>Logs</span>
                    </Link>
                </div>
                <div className='copyright'>
                    <div>
                        {
                            JSON.parse(localStorage.getItem("adm_setting"))
                                ?.copyright
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, actions)(LeftMenu);
